/*
    Quote related utilities functions
*/

frappe.provide("portal.utils.quotes");

$.extend(portal.utils.quotes, {

    display_mobile_confirmation_dialog: function(args, show_dialog){
        let res = frappe.boot.company_schedule;
        let bond_number = frappe.boot.website_settings.primary_phone;
        args.bond_number = bond_number;
        let $wrapper = portal.utils.quotes.get_mobile_confirmation_html_wrapper(args);
        portal.utils.quotes.init_callback_handler(args, $wrapper);
        if(!res.is_available){
            // disable modal
            $wrapper.on("show.bs.modal", ()=>{return false});
            return;
        }
        if(show_dialog){
            $wrapper.modal("show");
        }
    },

    get_mobile_confirmation_html_wrapper: function(args){
        let $wrapper = $("#mobile-confirmation-dialog");
        if(!$wrapper.length){
            $wrapper = $(`<div class="modal fade" id="mobile-confirmation-dialog" tabindex="-1" role="dialog" aria-labelledby="mobile-confirmation-dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-custom" role="document">
                    <div class="modal-content modal-content-custom border-0 shadow-sm">
                        <div class="modal-body modal-body-custom-height text-center pt-4 pb-5 py-lg-5 my-lg-5 px-4">
                            <img src="/assets/bond_assets/images/icons/number-icon.svg" alt="" class="img-fluid mb-3 logo">
                            <p class="modal-title gabriela-semi-bold mb-3 px-4 px-lg-0">
                                Is <span class="text-decoration mobile-no" style="color: #161617"></span> the best number to reach you?
                            </p>
                            <p class="modal-description">If not, you can enter a better one now. </p>
                            <form action="">
                                <div class="form-group row justify-content-center my-5 my-md-5">
                                    <div class="col-10 col-lg-6">
                                        <div class="input-group input-group-lg input-group-lg-control border border-dark rounded-pill">
                                            <input type="text" class="js-text-form form-control border-0 mobile-no" aria-describedby="mobile-no" placeholder="Current Number">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <span class="circle-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                            <path fill="currentColor" d="M9.256.036a2.833 2.833 0 0 0-2.013.833L2.08 6.036a4.679 4.679 0 0 0-1.381 3.33v2.075h2.076a4.679 4.679 0 0 0 3.33-1.381l5.167-5.162A2.85 2.85 0 0 0 9.256.036z"/>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-warning btn-lg rounded-pill call-me-btn">
                                    Call Me
                                    <svg class="phone-btn-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                        <path fill="currentColor" d="M12.682 8.981c-1.287-1.337-2.401.223-3.173 1.025-1.629-.89-3.301-2.45-4.03-4.19C7.837 3.59 7.366 3.323 4.707.693 3.378-.645 2.306.96 1.492 1.806c-.43.446-.643.981-.686 1.56-.429 4.01 5.703 11.275 10.59 11.586 1.457.09 2.015-.757 3.044-1.87.642-.67.642-1.605-.043-2.273z"/>
                                    </svg>
                                </button>
                            </form>
                        </div>
                        <div class="modal-close modal-close-custom">
                            <div class="modal-close-icon mx-auto" data-dismiss="modal" aria-label="Close">
                                <img src="/assets/bond_assets/images/icons/x-close.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>`).appendTo($("main"));
        }
        $wrapper.find("span.mobile-no").text(args.customer_number);
        $wrapper.find("input.mobile-no").val(args.customer_number);
        return $wrapper;
    },

    init_callback_handler: function(args, $wrapper){
        $wrapper.find(".call-me-btn").on("click", (e)=>{
            e.preventDefault();
            let dialog_number = cstr($wrapper.find("input.mobile-no").val());

            if(dialog_number.length < 10){
                frappe.msgprint(__("Please enter valid number."));
                return false;
            }
            args.customer_number = dialog_number;

            frappe.xcall("client_portal.insurances_form.rater.click_to_call", args).then((res)=>{
                if(res && res.message == false){
                    frappe.msgprint(__("Unable to place the call."))
                }else{
                    $wrapper.modal("hide");
                    this.display_callback_confirmation($wrapper);
                }
            });

            frappe.xcall("client_portal.insurances_form.rater.select_quote",{
                name: frappe.quote_id,
                service_type: args.service_type,
                service_name: args.service_name
            });
            
        });
    },

    display_callback_confirmation: function(args){

        let $wrapper = $(".call-placed-dialog");
        if(!$wrapper.length){
            $wrapper = $(`<div class="modal fade" id="call-placed-dialog" tabindex="-1" role="dialog" aria-labelledby="call-placed-dialog" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-custom" role="document">
                                <div class="modal-content modal-content-custom border-0 shadow-sm">
                                    <div class="modal-body modal-body-custom-height text-center pt-4 pb-5 py-lg-5 my-lg-5 px-4">
                                        <img src="/assets/bond_assets/images/icons/number-icon.svg" alt="" class="img-fluid mb-3 logo">
                                        <p class="modal-title gabriela-semi-bold mb-3 px-4 px-lg-0">Check your phone, a Bond Insurance Expert should be calling you any minute!</p>
                                    </div>
                                </div>
                            </div>
                        </div>`).appendTo($("main"));
        }
        $wrapper.modal('show');
    }

});

