/*
    Step Controller
*/
import "bond_assets/public/js/form/step";
import "./sub_step";

frappe.ui.UploadStep = class UploadStep  extends frappe.ui.Step{
    init_sub_step(idx, sub_step){
        let args = $.extend({}, {
            frm: this.frm,
            step: this,
            sub_step: sub_step,
            next_step: null,
            prev_step:null,
            prev_sub_step_obj: null,
            next_sub_step_obj: null,
            fields: sub_step.fields,
            df:sub_step.df,
            parent: this.$wrapper,
            idx: idx+1,
            next_sub_step: null,
            prev_sub_step: null,
            disable_fields_margin: true,
        });
        return new frappe.ui.UploadSubStep(args);
    }

    add_progress_handler(){
        super.add_progress_handler();
        this.$step.find(".progress-bar").removeClass("bg-dark");
    }

    add_progress_handler(){
        super.add_progress_handler();
        this.$step.find("button").removeClass("d-none");
    }

    get_progress_wrapper(){
        return $(`<div class="progress-step-wrapper progress-steps col-6 col-sm-3" step-name='${this.df.fieldname}'>
                    <div class="progress-title d-flex text-center text-black"></div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>`).appendTo(this.frm.$steps_wrapper.find(".steps-container"));
    }

    toggle_step_active_status(sub_step, is_back_btn=false){
        if(!sub_step){
            return;
        }
        let step = sub_step.step;
        if(step.$wrapper.is(":visible")){
            return;
        }
        sub_step.step.$step.find("button").removeAttr("disabled");
        sub_step.step.$step.css("opacity", "1");
        if(bond_assets.device.is_mobile_device()){
            if(is_back_btn){
                $(step.$wrapper).find(".js-form-field.d-none:not(.hide-control)").last().removeClass("d-none");
            }else{
                $(step.$wrapper).find(".js-form-field.d-none:not(.hide-control)").first().removeClass("d-none");
            }
        }else{
            $(step.$wrapper).find(".js-form-field.d-none").removeClass("d-none");
        }
    
        this.frm.$steps_wrapper.find(".progress-step-wrapper").removeClass("is-active");

        // add active class to current active step
        step.$step.addClass("is-active active");

        // hide all other and show current step
        step.frm.$parent.find(".step").hide();
        step.$wrapper.find(".substep-form").hide();
        step.$wrapper.find(`.substep-form[substep-name='${sub_step.df.fieldname}']`).show();
        step.add_margin_to_progress_icon();
        step.$wrapper.show();

    }
};