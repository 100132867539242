/*
    Existing Quote Lookup
*/

frappe.provide("client_portal.utils.quote_lookup");

client_portal.utils.quote_lookup.QuoteLookup = class QuoteConfirmation{
    constructor(args){
        this.set_defaults(args);
        this.make();
    }

    set_defaults(args){
        $.extend(this, args);
    }

    refresh(args){
        this.set_defaults(args);
        this.show_quotes();
    }

    make(){       
        this.popup = new frappe.ui.PopUp({
            title: __("It looks like you have already applied in the last 30 days."),
            primary_action_label: __("Review quotes"),
            only_initiate: true
        });

        $(`<div>
            <p>Click below to review your previous quotes and connect with an agent.</p>
            <p>If you are applying with new information, you can continue with your application to see new quotes.</p>
        </div>`).prependTo(this.popup.body);
        this.popup.$close_btn.addClass("justify-content-center d-none").removeClass("d-flex");
    }

    show_quotes(){
        if(this.popup.$wrapper.is(":visible")){
            return;
        }
        this.get_existing_quotes().then((res)=>{
            if(res && res.message && res.message.length){
                let details = res.message[0];
                let url = __("quotes/all?{0}", [formview.get_redirect_params(details.service_type, details.service_name, false)]);
                this.popup.set_primary_action("Review quote", ()=>{
                    return this.primary_action(res, url);
                });
                this.popup.show();
            }else{
                this.popup.hide();
            }
        });
    }

    get_existing_quotes(){
        return new Promise((resolve, reject)=>{
            return insurance_api.quotes.see_quotes({
                "service_type": this.service_type,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "zip_code": this.zip_code,
                "dob": this.dob
            }).then((res)=>{
                return resolve(res);
            });
        });
    }

    primary_action(res, url){
        window.location.href = url;
    }
}