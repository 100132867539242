/*
    Upload form attach controller
*/
import "bond_assets/public/js/form/controls/attach";

frappe.ui.form.UploadFormControlAttach = class UploadFormBondControlAttach extends frappe.ui.form.ControlAttach{

    make_wrapper() {
        this.$wrapper = $(`<div class="js-form-field col-12">
                <div class="form-disabled-div"></div>
                <div class="col-12">
                    <div class="documents-upload-form__fieldset-title field-info">
                        <h2 class="helvetica-font field-label"></h2>
                    </div>
                </div>
            </div>`).appendTo(this.parent);
        /*
		if(bond_assets.device.is_mobile_device()){
			if((!this.df.description || this.df.hide_on_mobile)
				&& !this.$wrapper.hasClass("sync-with-field-up-control")){
				this.$wrapper.find(".field-info").removeClass("mb-3 mb-sm-4");
			}else{
				this.$wrapper.find(".field-info").addClass("mb-0");
			}
			this.$wrapper.find(".form-field-inner-wrapper").removeClass("form-wrap")
			if(this.frm){
				this.$wrapper.find(".form-field-inner-wrapper").addClass("pl-3 pr-3");
			}
			if(this.frm){
				this.$wrapper.find(".field-info").addClass("pl-3 pr-3");
				this.$wrapper.addClass("form-question").addClass("mt-1");
				this.$wrapper.addClass("pl-4 pr-4");
				if(bond_assets.device.is_mobile_device()){
					this.$wrapper.find(".field-info").removeClass("mx-auto");
				}
			}
        }
        */
	}

    setup_wrapper_and_handler(){
        let required_files = [];
        if(this.custom_df && this.custom_df.required_files){
            required_files = this.custom_df.required_files;
        }
        required_files.forEach((fileinfo, idx)=>{
            this.meta[fileinfo.file_name] = fileinfo;
            $(`<div class="col-12 d-flex justify-content-center">
                <div class="input-wrapper input-upload-wrapper w-100">
                    <div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between mx-auto control-input-field-wrapper"
                        data-fieldname='${this.df.fieldname}' filename='${fileinfo.file_name}'>
                        <div class="control-input-value">
                            <span class="helvetica-font">
                                ${fileinfo.file_name}
                            </span>
                            <span class="helvetica-font file-name text-muted ml-4"></span>
                        </div>
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <span class="circle-icon">
                                    <svg id="icon_uploud" data-name="icon uploud" xmlns="http://www.w3.org/2000/svg" width="10.828" height="15.731" viewBox="0 0 10.828 15.731">
                                        <g id="Сгруппировать_35794" data-name="Сгруппировать 35794">
                                        <path id="Контур_92466" data-name="Контур 92466" d="M12.93,18.224H19V14.237h1.629a.751.751,0,0,0,.625-1.168L16.726,6.285a.917.917,0,0,0-1.527,0l-4.524,6.784a.751.751,0,0,0,.625,1.168H12.93Z" transform="translate(-10.548 -5.876)" fill="#fff"/>
                                        <rect id="Прямоугольник_5012" data-name="Прямоугольник 5012" width="6.08" height="1.57" transform="translate(2.388 14.161)" fill="#fff"/>
                                        </g>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.897" height="13.689" viewBox="0 0 15.897 13.689">
                                        <g id="icon_done" data-name="icon done" transform="translate(1.062 1.087)">
                                        <path id="Контур_92528" data-name="Контур 92528" d="M11.961-.057,5.647,7.769,1.832,3.955,0,5.787l5.668,5.646L13.8,1.6Z" transform="translate(0 0.057)" fill="#feffff" stroke="#feffff" stroke-width="1.5"/>
                                        </g>
                                    </svg>                                                                    
                                </span>
                            </span>
                        </div>
                        <div class="w-100 invalid-tooltip small py-3 d-none" data-fieldname=''>
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="mr-2 pb-1">
                                    <img src="/assets/bond_assets/images/icons/three-dots.svg" alt="" class="img-fluid">
                                </div>
                                <div class="text-left error-message"></div>
                            </div>
                        </div>
                        <input class="inputfile d-none attachment" type="file" name="upload-pdf" accept="${this.get_file_extensions("PDF")}"
                            data-fieldname='${this.df.fieldname}' data-fieldtype='${this.df.fieldtype}' file-type='${fileinfo.file_type}' 
                            filename='${fileinfo.file_name}' attached-to-name='${fileinfo.attached_to_name}' />
                    </div>
                </div>
            </div>`).appendTo(this.$wrapper);
        });
    }
};
