/*
    Upload form controller
*/
import "./step"

frappe.ui.UploadPortalForm =  class UploadForm extends frappe.ui.BasePortalForm{

    constructor(args){
        super(args);
        this.set_custom_upload_control();
        this.set_backdrop_for_missing_documents();
    }

    setup_default_classes(){
        //$("body").removeClass("tooltip-width body-form-with-legal-copy")
        this.$parent.addClass("documents-upload");
        this.$parent.removeClass("main-form-landing form-wrapper form-document-upload-main first-step-padding main-car-form main-mob-form-landing");
    }
    
    set_custom_upload_control(){
        frappe.ui.form.ControlAttach = frappe.ui.form.UploadFormControlAttach;
    }

    set_backdrop_for_missing_documents(){
        $(`<div class="bg-form-global-overlay position-fixed h-100 w-100 js-modal-overlay"></div>`).appendTo(this.$parent);
    }

    setup_progress(){
        super.setup_progress();
        this.$steps_wrapper.wrap(`<div class="global-progress-bar"></div>`);

    }

    init_step(idx, step){
        let _step = new frappe.ui.UploadStep({
            frm: this,
            prev_step: step.prev_step,
            next_step: step.next_step,
            df: step.df,
            sub_steps: step.sub_steps,
            idx: idx+1,
            parent: this.parent,
        });
        return _step;
    }

    save_form($btn, e){
        try{
            if(this.form_controller && this.form_controller.custom_loading_animation){
                this.form_controller.custom_loading_animation(this);
            }else{
                this.show_loading_animation();
            }
            frappe.call({
                method: "client_portal.forms.base.save_form",
                args:{
                    doc: this.doc,
                    path: this.path || {},
                    doctype: this.doctype,
                    files: this.files,
                    zip_code: this.url_params.zip_code,
                    referral_code: this.url_params.referral_code,
                    url_params: this.url_params,
                    ad_args: this.get_ad_params(),
                },
                callback: (res)=>{
                    res = res.message;
                    if(this.form_controller && this.form_controller.after_save){
                        this.form_controller.after_save(res, this);
                    }
                },
                error_handlers: this.error_handlers
            });
        }catch(e){
            console.log(e);
        }
    }
};