/*
    Sub Step Controller
*/
import "bond_assets/public/js/form/sub_step";
import "./attach";

frappe.ui.UploadSubStep = class UploadSubStep extends frappe.ui.SubStep{

    make(){
        super.make();
        this.$wrapper.find(".bg-gradient").remove();
        this.$wrapper.find(".form-layout").removeClass("position-relative");
        this.$wrapper.addClass("documents-upload-form");
    }

    get_cur_field(reverse=false, prev_step=null){
        let cur_field = super.get_cur_field(reverse, prev_step);
        if(!cur_field){
            cur_field = this.get_first_visible_field()
        }

        return cur_field;
    }

    init_step_handler(){
        super.init_step_handler().then(()=>{
            this.$actions.addClass("documents-upload-form__submit-wrapper");
            this.$actions.addClass("pt-5");
            this.$actions.removeClass("pt-0 mb-0 pb-0 position-relative");
            this.$submit_btn
                .removeClass("btn-lg")
                .addClass("btn-xl");
        });
    }

    toggle_back_btn(hide, is_last_step=false){
        this.$back_btn.toggleClass("d-none", hide);
        if(!hide){
            if(!is_last_step){
                this.set_btn_text(this.$submit_btn, "Save & Continue");
            }else{
                this.complete_submit_form("center");
            }
        }else{
            this.set_btn_text(this.$submit_btn, "Continue");
            if(is_last_step){
                this.complete_submit_form("center");
            }
        }
        if(!hide){
            this.$actions.addClass("documents-upload-form__submit-wrapper--addon-btn");
            this.$submit_btn.addClass("next-no-fade btn-xl");
        }else{
            this.$actions.removeClass("documents-upload-form__submit-wrapper--addon-btn");
        }        
    }
};
