
import 'bond_assets/public/js/form/step_form';

frappe.ui.BasePortalForm = class BondPortalForm extends frappe.ui.StepForm{
   make(){
       super.make();
       this.set_form_key();
   }
   get_ad_params(){
       let args = {};
       let session_args = ads.settings.get_google_analytical_info(true);
       if(session_args){
           $.extend(args, session_args);
       }
       let utm_args = ads.settings.get_utm_params(true);
       if(utm_args){
           $.extend(args, utm_args);
       }
       args.application_url = window.location.href;
       return args;
   }
   set_form_key(){
       frappe.call({
           method: "bond_assets.bond_assets.doctype.abandoned_form.abandoned_form.init_form",
           args:{
               form_type: this.doctype,
               ad_args: this.get_ad_params()
           },
           callback: (res)=>{
               localStorage.setItem("form_key", res.message);
               this.setup_canopy_connect(res);
           }
       });
   }

   setup_canopy_connect(res){
       return false;
       try{
           let args = {
               form: this,
               form_key: res.message,
               service_type: this.doctype
           };
           this.canopy_controller = new client_portal.fenris_canopy_lookup.BaseCanopyConnectController(args);
       }catch(e){
           console.log(e);
       }
   }
   save(){
       let flag = true;
       this.validate().then((flag)=>{
           this.save_form();
       });
       return flag;
   }

   validate(){
       return new Promise((resolve, reject)=>{
           try{
               let flag = true;
               for(var i=0; i<this.forms.length; i++){
                   if(!this.forms[i].get_values()){
                       flag = false;
                       break;
                   }
               }
               resolve(flag);
           }catch(e){
               reject();
           }
       });
   }

   show_loading_animation(){
      this.animation_controller.play_form_finish_animation();
   }

   hide_loading_animation(){
       this.animation_controller.stop_form_finish_animation();
   }

   remove_abandoned_form(res, quoting_failed=false){
       quoting_failed = is_null(quoting_failed)?false:true;
       return new Promise((resolve, reject)=>{
           frappe.xcall("bond_assets.bond_assets.doctype.abandoned_form.abandoned_form.remvove_abandoned_form",{
               form_key: localStorage.getItem("form_key"),
               is_quoting_failed: !is_null(quoting_failed)?true:false,
               res: res,
           }).then((res)=>{
               if(!quoting_failed){
                   localStorage.removeItem("form_key");
               }
               resolve(res);
           });
       });
   }

   form_handlers = {

       401: function(xhr) {
           // session expired
           //if(frappe.app.session_expired_dialog && frappe.app.session_expired_dialog.display) {
           //	frappe.app.redirect_to_login();
           //} else {
           //	frappe.app.handle_session_expired();
           //}
       },
       403: function(xhr) {
           // permission issue
           if (frappe.get_cookie('sid')==='Guest') {
               // session expired
               frappe.app.handle_session_expired();
           }
           else if(xhr.responseJSON && xhr.responseJSON._error_message) {
               frappe.msgprint({
                   title:__("Not permitted"), indicator:'red',
                   message: xhr.responseJSON._error_message
               });

               xhr.responseJSON._server_messages = null;
           }
           else if (xhr.responseJSON && xhr.responseJSON._server_messages) {
               var _server_messages = JSON.parse(xhr.responseJSON._server_messages);

               // avoid double messages
               if (_server_messages.indexOf(__("Not permitted"))!==-1) {
                   return;
               }
           }
           else {
               frappe.msgprint({
                   title:__("Not permitted"), indicator:'red',
                   message: __('You do not have enough permissions to access this resource. Please contact your manager to get access.')});
           }


       },
       508: function(xhr) {
           // another transaction is locking
       },
       413: function(data, xhr) {
           frappe.msgprint({indicator:'red', title:__('File too big'), message:__("File size exceeded the maximum allowed size of {0} MB",
               [(frappe.boot.max_file_size || 5242880) / 1048576])});
       },
       417: function(xhr) {

       },
       501: function(data, xhr) {
           if(typeof data === "string") data = JSON.parse(data);
       },
       500: function(xhr) {
           console.log(xhr);
       },
       504: function(xhr) {
           console.log(xhr);
       },
       502: function(xhr) {
           console.log(xhr);
       }
   }

   store_form(fieldname, field_group){
       if(this.in_execution)return;
       let field_info = {};
       $.extend(field_info, {
           fieldname: fieldname,
           value: field_group.get_value(fieldname),
           idx: field_group.fields_dict[fieldname].df.idx,
       });
       this.in_execution = true;
       frappe.call({
           method: "bond_assets.bond_assets.doctype.abandoned_form.abandoned_form.update_form",
           args:{
               form_key: localStorage.getItem("form_key"),
               sub_step_name: field_group.sub_step.df.fieldname,
               field_info: field_info
           },
           callback: (res)=>{
               this.in_execution = false;
           }
       });
   }
}


/*
   Form for rating
*/
frappe.ui.RaterPortalForm = class RaterPortalForm extends frappe.ui.BasePortalForm{

   make(){
       this.setup_third_party_forms();
       super.make();
   }

   setup_third_party_forms(){
       this.third_party_forms = [];
       let form_type = this.doctype;
       if(in_list(["Homeowners", "Condo", "Renters", "Personal Auto"], this.doctype)){
           if(in_list(["Condo", "Renters"], this.doctype)){
               form_type = "Homeowners";
           }
           form_type = form_type.replace(" ", "");
           try{
               let canopy_controller_name = `${form_type}CanopyConnectController`;
               let fenris_controller_name = `${form_type}FenrisConnectController`;
               this.third_party_forms.push(
                   new client_portal.fenris_canopy_lookup[canopy_controller_name]()
               )
               this.third_party_forms.push(
                   new client_portal.fenris_canopy_lookup[fenris_controller_name]()
               )
           }catch(e){
               console.log(e);
           }
       }
   }
   is_manual_quotes(){
       if(this.formmeta.is_manual==1){
           return true
       }
       return false;

   }
   save_form($btn, e){
       try{
           if(this.form_controller && this.form_controller.custom_loading_animation){
               this.form_controller.custom_loading_animation(this);
           }else{
               this.show_loading_animation();
           }
           this.doc.is_manual_quotes = this.is_manual_quotes();
           this.doc.is_external_rating_state_client = this.searchParams.get("is-external-rating-state-client");
           frappe.call({
               method: "client_portal.insurances_form.form.save_form",
               args:{
                   doc: this.doc,
                   path: this.path || {},
                   doctype: this.doctype,
                   files: this.files,
                   zip_code: this.url_params.zip_code,
                   referral_code: this.url_params.referral_code,
                   url_params: this.url_params,
                   ad_args: this.get_ad_params(),
               },
               callback: (res)=>{

                   if(this.form_controller.after_save){
                       this.form_controller.after_save(res, this);
                   }else{
                       if(res.message){
                           res = res.message;
                       }
                       this.reference_doc = res;
                       this.enable_schedule_button();
                   }
                   /*
                   res = res.message;
                   let flag = true;
                   if(this.form_controller && this.form_controller.after_save){
                       flag = this.form_controller.after_save(res);
                   }
                   if(!flag)return false;
                   if(!this.is_manual_quotes()){
                   
                       if(!$.isPlainObject(res)){
                           return this.hide_loading_animation();
                       }
                       this.is_all_quotes_loaded(this.doctype, res.name);
                   }
                   */
               },
               error_handlers: this.error_handlers
           });
       }catch(e){
           console.log(e);
       }
   }

   is_all_quotes_loaded(service_type, service_name){
       if(!(service_type && service_name)){
           this.redirect_to_failure_page({}, service_type, service_name);
           return false;
       }
       let timeout = bond_assets.website_settings.get_client_application_timeout() * 1000;
       bond_assets.dev.logger.log_info(timeout);
       setTimeout(()=>{
           frappe.call({
               method: "client_portal.insurances_form.rater.get_quotes",
               args:{
                   service_type: service_type,
                   service_name: service_name,
               },
               callback: (res)=>{
                   res = res.message;
                   if($.isPlainObject(res)){
                       if(res.is_external_rating_state_client){
                           this.redirect_to_success_page(res, service_type, service_name);
                       }
                       else if(!res.incomplete_quotes){
                           this.redirect_to_success_page(res, service_type, service_name);
                       }else if(res && res.message && res.message.status == "Error"){
                           this.redirect_to_failure_page(res, service_type, service_name);
                       }
                   }
               },
               error_handlers: this.form_handlers
           });
       }, timeout);
   }

   redirect_to_success_page(res, service_type, service_name){
       this.remove_abandoned_form(res).then(()=>{
           window.location.href = "/quotes/all?"+cstr(this.get_redirect_params(service_type, service_name, false));
       });
   }
   redirect_to_failure_page(res, service_type, service_name){
       window.location.href = "/quotes/oops?"+cstr(this.get_redirect_params(service_type, service_name, true));
   }
   get_redirect_params(service_type, service_name, is_failure){
       let args = {
           service_type: service_type,
           service_name: service_name?service_name: "",
       };
       if(is_failure){
           args.form_key = cstr(localStorage.getItem("form_key"));
       }
       let params = $.param(args);
       let analytical_params = ads.settings.get_google_analytical_info();
       if(analytical_params){
           params += "&"+cstr(analytical_params);
       }
       return params;
   }
}

/*
   Client Application Website Form
*/
frappe.ui.WebsiteInsuranceForm = class WesbiteInsuranceForm extends frappe.ui.BasePortalForm{

   save_form(){
        let show_animation = true;
        if(this.form_controller && this.form_controller.disable_loading_animation){
            show_animation = false;
        }
        if(show_animation){
            this.show_loading_animation();
        }else{
            frappe.dom.freeze("Please wait while we're processing your info...");
        }
       frappe.call({
           method: "client_portal.forms.base.save_form",
           args:{
               doc: this.doc,
               path: this.path || {},
               doctype: this.doctype,
               zip_code: this.url_params.zip_code,
               referral_code: this.url_params.referral_code,
               url_params: this.url_params,
           },
           //freeze: true,
           //freeze_message: freeze_msg,
           callback: (res)=>{
               this.handle_response(res);
                if(show_animation){
                    this.enable_schedule_button();
                }
           },
           error: (e)=>{
               cbond_assets.dev.logger.error_log(e);
           }
       })
   }

   handle_response(res){
       try{
           if(res && res.doctype && res.docname){
               this.reference_doctype = res.doctype;
               this.reference_docname = res.docname;
           }
           if(this.form_controller && this.form_controller.after_save){
               this.form_controller.after_save(res);
           }else{
               let data = res.message;
               if(data && data.redirect_url){
                   window.location.href = data.redirect_url;
               }
           }
       }catch(e){
           bond_assets.dev.logger.error_log(e);
       }
   }

   show_loading_animation(){
       this.animation_controller.display_form_finish_static_animation();
       /*
       this.animation_controller.$form_finish_animation_wrapper
           .find(".animation-back-action-btn").removeClass("d-none");
       */
    }
}